import React from 'react';
import { Helmet } from "react-helmet"
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    background: linear-gradient(-45deg, #E4FFF9, #fff);
    overflow: hidden;
}


html {
    font-size: 16px;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

h1 {
    margin: 0;
    
}

h2 {
    margin: 0;
    font-family: antarctican-headline, sans-serif;
}

h3 {
    margin: 0;
}

p { 
    margin-bottom: 16px;
}

svg {
    width: 100%;
}
`

export const wrapRootElement = ({ element }) => (
    <>
        <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/xop8ltr.css" />
        </Helmet>
        <GlobalStyle />
        {element}
    </>
);